import * as React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';

import ListIcon from '@mui/icons-material/List';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Box, Grid } from '@mui/material';

import StatusBar, { eposStatuses } from 'components/StatusBar/StatusBar';
import SearchFieldWithSuggest from 'components/SearchField/SearchFieldWithSuggest';
import { isSearchValueAcceptable } from 'components/SearchField/business';
import ErrorsFilter from 'components/ErrorsFilter/ErrorsFilter';
import ColumnChooser from 'components/ColumnChooser/ColumnChooser';
import SelectedQuantity from 'components/SelectedQuantity/SelectedQuantity';
import TerminalsTable from 'pages/Epos/components/TerminalsTable/TerminalsTable';
import Filters from 'components/Filters/Filters';
import TerminalsTiles from 'pages/Epos/components/TerminalsTiles/TerminalsTiles';
import NoResultsFound from 'components/NoResultsFound';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import { EposContext } from 'context/epos';

import { IHeadRow, headRowsAll, headRowsDefault } from 'data';
import { EPOS_STATUS, EPOS_FILTERS, IFilterList, filterListInitial, IFilterListItem, localStorageFiltersKeys } from 'const';

import defaultSorting from 'utils/defaultEposSorting';
import { toggleItemInArray } from 'utils/arrayMethods';
import { IEposListItemModel } from 'api/models/epos';
import BulkActionsMenu from '../BulkActionsMenu/BulkActionsMenu';
import { stableSort } from 'utils/stableSort';
import { clearNotExistingData } from '../../utils/utils';
import { getFromLocalStorage } from 'utils/devUtils';

import { useWidth } from 'utils/customHooks';

const { useState, useEffect, useContext } = React;

interface IData {
    name: string;
    location: string;
    status: string;
    deliveryTime: string;
    errors: Array<string | number>;
    os?: string;
    shell?: string;
    ip?: string;
    dataSetVersion?: string;
    minStake?: number;
}

type Order = 'asc' | 'desc';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(0)}`,

        [theme.breakpoints.down('lg')]: {
            padding: `${theme.spacing(6)} ${theme.spacing(2)} 0`,
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: theme.spacing(6),
            flexDirection: 'column',
            padding: `${theme.spacing(2)} ${theme.spacing(1.5)} 0`,
        }
    },
    actionsWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            minHeight: '36px',
            order: 1
        },
    },
    actions: {
        display: 'flex'
    },
    options: {
        display: 'flex',
        minHeight: theme.spacing(4),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('lg')]: {
            marginTop: theme.spacing(1)
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        },
    },
    title: {
        margin: `${theme.spacing(1)} 0`,
        fontSize: '1.5rem',
        fontWeight: 500,

        [theme.breakpoints.down('sm')]: {
            margin: 0
        },
    },
    searchWrap: {
        width: '300px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0
        }
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: `${theme.spacing(2)} ${theme.spacing(6)} 0`,
        backgroundColor: theme.palette.common.white,

        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            flexGrow: 'initial',
            padding: `${theme.spacing(2)} 0`,
        }
    },
    errorsBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
        minHeight: theme.spacing(4),

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            marginBottom: theme.spacing(2),
        }
    },
    errorsBlockTilesView: {
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 2, 2)
        }
    },
    blockToRight: {
        justifyContent: 'flex-end'
    },
    tableWrapper: {
        flex: 1,

        '& .ReactVirtualized__Grid': {
            outline: 'none'
        },
    },
    button: {
        padding: '5px',
        borderRadius: '4px',
        textTransform: 'none',
        marginLeft: theme.spacing(1),
        backgroundColor: `${theme.palette.grey[100]}`
    },
    smallButton: {
        position: 'relative',
        zIndex: 2,
        minWidth: theme.spacing(5)
    },
    icon: {
        position: 'relative',
        zIndex: 1
    },
    columnChooser: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }
}));

const eposStatusesCountInit = {
    [eposStatuses.all]: 0,
    [eposStatuses.errors]: 0,
    [eposStatuses.normal]: 0,
    [eposStatuses.offline]: 0,
    [eposStatuses.maintenance]: 0,
    [eposStatuses.pendingMaintenance]: 0,
};

const TableGrid = (props) => {
    const classes = useStyles({});
    const isMobileView = useWidth() === 'xs';
    const { translations, permissions }: GlobalContextModel = React.useContext(GlobalContext);
    const { eposList, filtersList } = useContext(EposContext) || { eposList: [], filtersList: filterListInitial };

    const {
        restartTerminalPermission,
        restartShellPermission,
        restartPrinterPermission,
        reinitBillValPermission,
        maintenancePermission,
        tilesViewPermission
    } = permissions;

    const actionPermissions =
        restartTerminalPermission ||
        restartShellPermission ||
        restartPrinterPermission ||
        reinitBillValPermission ||
        maintenancePermission;

    const localStorageFilters = getFromLocalStorage(localStorageFiltersKeys.healthMonotorFilters);

    const [defaultSortingData, setDefaultSortingData] = useState<Array<IEposListItemModel>>([]);
    const [rows, setRows] = useState<Array<IEposListItemModel>>([]);
    const [activeColumns, setActiveColumns] = useState(JSON.parse(localStorage.getItem('activeColumns')) || [...headRowsDefault]);
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<keyof IData>('errors');
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [filterStatusId, setFilterStatusId] = useState<number>(eposStatuses.all);
    const [searchText, setSearchText] = useState<string>('');
    const [filterList, setFilterList] = useState<IFilterList>(filterListInitial);
    let [activeFilterIds, setActiveFilterIds] = useState<IFilterList>(structuredClone(localStorageFilters || filterListInitial));
    let [appliedActiveFilters, setAppliedActiveFilters] = useState<IFilterList>(structuredClone(localStorageFilters || filterListInitial));
    const [filterQty, setfilterQty] = useState(countFilterQty());

    const [eposStatusesCount, setEposStatusesCount] = useState(eposStatusesCountInit);

    const [tilesView, setTilesView] = useState(Boolean(tilesViewPermission &&
            JSON.parse(localStorage.getItem('tilesView'))
    ));

    const [errorsSelectedTypes, setErrorsSelectedTypes] = useState<Array<number>>([]);

    const [isSaveFilters, setIsSaveFilters] = useState(!!localStorageFilters);

    useEffect(() => {
        const translatedEposList = (eposList as Array<IEposListItemModel>).map((epos) => {
            const newEpos = Object.assign({}, epos);

            newEpos.statusName = translations[newEpos.statusName];

            return newEpos;
        });
        const sortedData = defaultSorting(translatedEposList, order);

        clearNonExistingFilters();
        countStatuses(sortedData);
        setDefaultSortingData(sortedData);
        handleActiveFilters(filterStatusId, sortedData);
        setFilterList(filtersList);
    }, [eposList, translations, filtersList]);

    useEffect(() => {
        //clear state after initial render
        window.history.replaceState({}, '');
    }, []);

    function clearNonExistingFilters() {
        activeFilterIds = clearNotExistingData(filtersList, activeFilterIds);
        appliedActiveFilters = clearNotExistingData(filtersList, appliedActiveFilters);
        setfilterQty(countFilterQty());
        setActiveFilterIds(activeFilterIds);
        setAppliedActiveFilters(appliedActiveFilters);
    }

    function handleRequestSort(event: React.MouseEvent<HTMLElement>, property: keyof IData) {
        const isDesc = orderBy === property && order === 'desc';

        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
        setRows(defaultSorting(rows, order));
    }

    function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            const newSelectedIds = rows.map((n) => n.id);

            setSelectedIds(newSelectedIds);

            return;
        }

        setSelectedIds([]);
    }

    function handleClearSelectedRows() {
        setSelectedIds([]);
    }

    function handleTirminalSelect(event: React.MouseEvent<unknown>, id: number) {
        const selectedIndex = selectedIds.findIndex(item => item === id);
        const newSelectedIds = toggleItemInArray(id, selectedIndex, selectedIds);

        setSelectedIds(newSelectedIds);
    }

    function handleChangePage(event: React.MouseEvent<HTMLElement>, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function countStatuses(data) {
        let eposStatusesCount = {
            [eposStatuses.all]: data.length,
            [eposStatuses.errors]: 0,
            [eposStatuses.normal]: 0,
            [eposStatuses.offline]: 0,
            [eposStatuses.maintenance]: 0,
            [eposStatuses.pendingMaintenance]: 0
        };

        data.length && data.forEach((e) => {
            if (e.status !== EPOS_STATUS.OFFLINE && e.errors && e.errors.length) { eposStatusesCount[eposStatuses.errors]++; }

            !(e.errors?.length) && e.status === EPOS_STATUS.ONLINE && eposStatusesCount[eposStatuses.normal]++;
            e.status === EPOS_STATUS.OFFLINE && eposStatusesCount[eposStatuses.offline]++;
            e.status === EPOS_STATUS.MAINTENANCE && eposStatusesCount[eposStatuses.maintenance]++;
            e.status === EPOS_STATUS.PENDING_MAINTENANCE && eposStatusesCount[eposStatuses.pendingMaintenance]++;
        });
        setEposStatusesCount(eposStatusesCount);
        !eposStatusesCount[filterStatusId] && setFilterStatusId(eposStatuses.all);
    }

    function doFilteringByStatus(id: number = filterStatusId, arr = defaultSortingData, text = searchText) {
        const fltId = id === null ? filterStatusId : id;
        let filteredRows = [...arr];

        if (fltId === eposStatuses.errors) {
            filteredRows = filteredRows.filter((e) => e.errors?.length);
        } else if (fltId === eposStatuses.normal) {
            filteredRows = filteredRows.filter((e) => !(e.errors?.length) && e.status === EPOS_STATUS.ONLINE);
        } else if (fltId === eposStatuses.offline) {
            filteredRows = filteredRows.filter((e) => e.status === EPOS_STATUS.OFFLINE);
        } else if (fltId === eposStatuses.maintenance) {
            filteredRows = filteredRows.filter((e) => e.status === EPOS_STATUS.MAINTENANCE);
        } else if (fltId === eposStatuses.pendingMaintenance) {
            filteredRows = filteredRows.filter((e) => e.status === EPOS_STATUS.PENDING_MAINTENANCE);
        }

        return doFilteringBySearch(text, filteredRows);
    }

    function doFilteringBySearch(text: string, arr = defaultSortingData) {
        const fltText = text.toLowerCase();
        let filteredRows = [...arr];

        if (fltText) {
            filteredRows = filteredRows.filter(({
                name, location, macAddress, machineId, id
            }) => isSearchValueAcceptable([name, location, macAddress, machineId, String(id)], fltText));
        }

        return filteredRows;
    }

    function filteringByTerminalFilters(key: string, item: IFilterListItem) {
        const filterArr = toggleFilters(item, key, activeFilterIds[key]);

        setActiveFilterIds({ ...activeFilterIds, [key]: filterArr });
    }

    function onStatusBarFiltering(id: number) {
        handleActiveFilters(id);

        setFilterStatusId(id);
        setPage(0);
        setSelectedIds([]);

        id !== filterStatusId && setErrorsSelectedTypes([]);
    }

    function countFilterQty() {
        let i = 0;

        for (let filter of Object.values(appliedActiveFilters)) {
            filter.length && i++;
        }

        return i;
    }

    function handleAppliedFilters() {
        isSaveFilters && localStorage.setItem(localStorageFiltersKeys.healthMonotorFilters, JSON.stringify(activeFilterIds));
        appliedActiveFilters = activeFilterIds;
        handleActiveFilters();
        setfilterQty(countFilterQty());
        setAppliedActiveFilters(activeFilterIds);
        setPage(0);
        setSelectedIds([]);
    }

    const handleSaveFilters = () => {
        isSaveFilters && localStorage.removeItem(localStorageFiltersKeys.healthMonotorFilters);

        setIsSaveFilters(!isSaveFilters);
    };

    function handleActiveFilters(filterId = filterStatusId, dataArr = defaultSortingData, text = searchText, selectedErrorTypes = errorsSelectedTypes) {
        let statusFilteredRows = doFilteringByStatus(filterId, dataArr, text);

        if (filterId === eposStatuses.errors) {
            if (selectedErrorTypes.length) {
                statusFilteredRows = statusFilteredRows.filter(
                    (e) => e.errors?.some((er) => selectedErrorTypes.some((err) => er.errorId === err))
                );
            }
        }

        if (statusFilteredRows.length) {
            setRows(filteringByFilters(statusFilteredRows));
        } else {
            setRows([]);
        }
    }

    function filteringByFilters(rows: Array<IEposListItemModel> = defaultSortingData, appliedFilters = appliedActiveFilters) {
        return rows.filter(row => {
            return Object.keys(EPOS_FILTERS).every(key => {
                if (appliedFilters[key].length) {
                    return appliedFilters[key].some(e => e.name === row[key]);
                }

                return true;
            });
        });
    }

    function handleClearFilters() {
        appliedActiveFilters = filterListInitial;
        localStorage.removeItem(localStorageFiltersKeys.healthMonotorFilters);
        handleActiveFilters();

        setIsSaveFilters(false);
        setfilterQty(0);
        setActiveFilterIds({ ...filterListInitial });
        setAppliedActiveFilters({ ...filterListInitial });
    }

    function handleClearActiveFilters() {
        setActiveFilterIds(appliedActiveFilters);
    }

    function handleColumn(event, id: string) {
        const activeColumnsUpdated = toggleColumns(activeColumns, id);

        localStorage.setItem('activeColumns', JSON.stringify(activeColumnsUpdated));
        setActiveColumns(activeColumnsUpdated);
    }

    function toggleColumns(arr: Array<IHeadRow>, id: string) {
        const item = headRowsAll.find(item => item.id === id);
        const selectedIndex = arr.findIndex(item => item.id === id);

        return toggleItemInArray(item, selectedIndex, activeColumns).sort((a, b) => a.position - b.position);
    }

    function toggleFilters(item: IFilterListItem, key: string, arr: Array<IFilterListItem>) {
        const selectedIndex = arr.findIndex(arrItem => arrItem.id === item.id);

        return toggleItemInArray(item, selectedIndex, arr);
    }

    function onErrorFiltering(errorsArr: Array<number>) {
        const selectedErrorTypes = errorsArr.filter(Boolean);

        setErrorsSelectedTypes(selectedErrorTypes);

        if (!selectedErrorTypes.length) {
            setFilterStatusId(eposStatuses.all);
            handleActiveFilters(eposStatuses.all, defaultSortingData, searchText, selectedErrorTypes);
        } else {
            const terminalsWithErrors = defaultSortingData
                .filter((e) => e.errors?.some((er) => selectedErrorTypes
                    .some((err) => er.errorId === err)
                ));

            setFilterStatusId(eposStatuses.errors);
            handleActiveFilters(eposStatuses.errors, terminalsWithErrors, searchText, selectedErrorTypes);
            setSelectedIds([]);
        }

        setPage(0);
    }

    function textSearchChange(newText: string) {
        if (newText !== searchText) {
            const validText = newText || '';
            const filteredRows = doFilteringByStatus(eposStatuses.all, defaultSortingData, validText);

            setPage(0);
            setRows(filteredRows);

            setActiveFilterIds({ ...filterListInitial });
            setAppliedActiveFilters({ ...filterListInitial });
            setfilterQty(0);

            setFilterStatusId(eposStatuses.all);
            setSearchText(validText);
            setSelectedIds([]);
        }
    }

    function toggleTilesView() {
        localStorage.setItem('tilesView', JSON.stringify(!tilesView));
        setOrder('desc');
        setOrderBy('errors');
        setTilesView(!tilesView);
    }

    function handleRedirect(id: number) {
        props.history.push({
            pathname: `/epos/${id}`,
        });
    }

    function resetToDefault() {
        handleClearFilters();
        textSearchChange('');
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.actionsWrap}>
                    <h1 className={classes.title}>
                        {translations['gen-health-monitor']}
                    </h1>
                    {isMobileView &&
                        <div className={classes.actions}>
                            <Filters
                                isMobileView={isMobileView}
                                filterQty={filterQty}
                                filterList={filterList}
                                activeFilterIds={activeFilterIds}
                                appliedActiveFilters={appliedActiveFilters}
                                handleFilter={filteringByTerminalFilters}
                                handleAppliedFilters={handleAppliedFilters}
                                handleClearFilters={handleClearFilters}
                                handleClearActiveFilters={handleClearActiveFilters}
                                handleSaveFilters={handleSaveFilters}
                                isSaveFilters={isSaveFilters}
                            />
                            {tilesViewPermission &&
                                <Button
                                    className={clsx(classes.button, isMobileView && classes.smallButton)}
                                    variant="outlined"
                                    onClick={toggleTilesView}
                                    color="primary"
                                >
                                    {
                                        tilesView
                                            ? <ListIcon onClick={toggleTilesView} className={classes.icon} />
                                            : <ViewModuleIcon onClick={toggleTilesView} className={classes.icon} />
                                    }
                                </Button>}
                        </div>
                    }
                </div>
                <div className={classes.searchWrap}>
                    <SearchFieldWithSuggest
                        data={rows.map(({ name, location, macAddress, machineId, id }) => ({
                            name,
                            location,
                            macAddress,
                            machineId,
                            id
                        }))}
                        onTextChange={textSearchChange}
                        searchValue={searchText}
                    />
                </div>
            </div>
            <StatusBar
                eposStatusesCount={eposStatusesCount}
                activeStatus={filterStatusId}
                onStatusBarFiltering={onStatusBarFiltering}
            />
            <div className={classes.paper}>
                <div
                    className={clsx(
                        classes.errorsBlock,
                        tilesView && classes.errorsBlockTilesView,
                        !defaultSortingData.some(e => e?.errors?.length) && classes.blockToRight
                    )}
                >
                    <ErrorsFilter
                        isMobileView={isMobileView}
                        data={defaultSortingData}
                        onErrorFiltering={onErrorFiltering}
                        filterStatusId={filterStatusId}
                    />
                    {
                        !isMobileView &&
                            <div className={classes.options}>
                                {
                                    rows.length && tilesView && actionPermissions
                                        ? (
                                            <SelectedQuantity
                                                selectedIds={selectedIds}
                                                rowsCount={rows.length}
                                                handleSelectAllClick={handleSelectAllClick}
                                                roundBorder
                                            />)
                                        : null
                                }
                                {
                                    tilesView && actionPermissions && (selectedIds && selectedIds.length > 0 && selectedIds.length <= rows.length) &&
                                    (
                                        <BulkActionsMenu
                                            rows={rows}
                                            selectedIds={selectedIds}
                                            isMobileView={isMobileView}
                                            handleClearSelectedRows={handleClearSelectedRows}
                                        />)
                                }
                                <Filters
                                    isMobileView={isMobileView}
                                    filterQty={filterQty}
                                    filterList={filterList}
                                    activeFilterIds={activeFilterIds}
                                    appliedActiveFilters={appliedActiveFilters}
                                    handleFilter={filteringByTerminalFilters}
                                    handleAppliedFilters={handleAppliedFilters}
                                    handleClearFilters={handleClearFilters}
                                    handleClearActiveFilters={handleClearActiveFilters}
                                    handleSaveFilters={handleSaveFilters}
                                    isSaveFilters={isSaveFilters}
                                />
                                {tilesViewPermission &&
                                    <Button
                                        className={classes.button}
                                        variant="outlined"
                                        onClick={toggleTilesView}
                                    >
                                        {
                                            tilesView ? <ListIcon onClick={toggleTilesView} /> : <ViewModuleIcon onClick={toggleTilesView} />
                                        }
                                    </Button>
                                }
                                <ColumnChooser
                                    activeColumns={activeColumns}
                                    allColumns={headRowsAll}
                                    onHandleClick={handleColumn}
                                    disabled={tilesView}
                                />
                            </div>
                    }
                </div>
                <Box className={classes.tableWrapper}>
                    {
                        stableSort(rows, order, orderBy).length
                            ? tilesView
                                ? (
                                    <TerminalsTiles
                                        rows={stableSort(rows, order, orderBy)}
                                        selectedIds={selectedIds}
                                        rowsPerPage={rowsPerPage}
                                        handleSelectAllClick={handleSelectAllClick}
                                        handleRequestSort={handleRequestSort}
                                        handleTirminalSelect={handleTirminalSelect}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        handleClearSelectedRows={handleClearSelectedRows}
                                        handleRedirect={handleRedirect}
                                        isMobileView={isMobileView}
                                    />)
                                : (
                                    <TerminalsTable
                                        rows={stableSort(rows, order, orderBy)}
                                        activeColumns={activeColumns}
                                        selectedIds={selectedIds}
                                        order={order}
                                        orderBy={orderBy}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        handleSelectAllClick={handleSelectAllClick}
                                        handleRequestSort={handleRequestSort}
                                        handleTirminalSelect={handleTirminalSelect}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        handleClearSelectedRows={handleClearSelectedRows}
                                        handleRedirect={handleRedirect}
                                        isMobileView={isMobileView}
                                    />)
                            : (
                                <Box paddingTop={12}>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <NoResultsFound
                                                onResetClick={resetToDefault}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>)
                    }
                </Box>
            </div>
        </div>
    );
};

export default withRouter(TableGrid);
